import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const Working_Commitee = () => {
  const workingCommittee = [
    {
      Sno: 1,
      id: 1,
      name: "Dr.D.SRUJANA",
      designation: "CHAIRPERSON & PRINCIPAL OF DBRALC", 
    },
    {
      Sno: 2,
      id: 2,
      name: "Prof. Dr.Y.VISHNU PRIYA",
      designation: "DIRECTOR OF DBRAEI & CO-CORDINATOR NAAC ",
    },

    {
      Sno: 3,
      id: 3,
      name: "Dr. J. E. PADMAJA",
      designation: "COORDINATOR IQAC &  NAAC",
    },
    {
      Sno: 4,
      id: 4,
      name: "Ms.S.P.NALINI",
      designation: "Member",
    },
    {
      Sno: 5,
      id: 5,
      name: "Dr.M.SRIDEVI",
      designation: "Member",
    },
    {
      Sno: 6,
      id: 6,
      name: "Mrs.M.VARALAKSHMI",
      designation: "Member",
    },
    {
      Sno: 7,
      id: 7,
      name: "Mr.VASANTH KUMAR",
      designation: "Member",
    },
    {
      Sno: 8,
      id: 8,
      name: "Mrs.K.SIRISHA",
      designation: "Member",
    },
    {
      Sno: 9,
      id: 9,
      name: "Mr.P.V.RAMCHANDRA RAO",
      designation: "Member",
    },
    {
      Sno: 10,
      id: 10,
      name: "Mrs.MANYATHA PRASAD WAGHRAY",
      designation:
        "Member",
    },
  ];
  return (
    <section className="container py-4">
      <div>
        <Typography variant="h6" component="div" sx={{ my: 2 }}>
          WORKING COMMITTEE
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.NO</TableCell>
              <TableCell align="">Name of the Member</TableCell>
              <TableCell align="">Designation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workingCommittee.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="">{row.name}</TableCell>
                <TableCell align="">{row.designation}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default Working_Commitee;
