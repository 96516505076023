import React, { Component } from "react";
import Slider from "react-slick";
import Banner from '../../Assets/JSON/MV.json';

// Preload images function
const preloadImages = (urls) => {
  urls.forEach(url => {
    const img = new Image();
    img.src = url;
  });
};

export default class Header_Carousel extends Component {
  componentDidMount() {
    preloadImages(Banner.HomePage_Details.Banners);
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
      slidesToShow: 1,
      speed: 250,
      slidesToScroll: 1
    };

    return (
      <section>
        <Slider {...settings}>
          {Banner.HomePage_Details.Banners.map((banner, index) => (
            <div key={index}>
              <img
                src={banner}
                height={500}
                className="w-100 objectFit blurred-image"
                alt={`banner-${index}`}
                style={{ objectFit: 'cover' }} // Ensure the image covers the area properly
                onLoad={(e) => e.target.classList.remove('blurred-image')}
              />
              <style>
                {`
                  .blurred-image {
                    filter: blur(10px);
                    transition: filter 0.5s ease-out;
                  }
                `}
              </style>
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}
