import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Arrow_icon_right from "../../Assets/Icons/Arrow_Small_Right.svg";
import { Breadcrumbs, Typography } from "@mui/material";
import NAAC_DATA from "../../Assets/JSON/NAAC/Naac.json";

const NAACComponent = () => {
  const navigate = useNavigate();
  const navigation_url = (path) => {
    if (!path.pathurl) {
      navigate(path.intetnalpath);
    } else {
      navigate(`/naac/${path.ID}/${path.pathname}`);
    }
  };
  return (
    <section className="container py-5">
      <Breadcrumbs
        separator="›"
        sx={{ width: "100%", display: "block", marginBottom: "10px" }}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit" to="/">
          <b>Home</b>
        </Link>
        <Typography underline="hover" color="text.primary">
          <b>NAAC</b>
        </Typography>
      </Breadcrumbs>
      <div className="mb-4">
        <Typography variant="h4">NAAC Details</Typography>
      </div>

      <div>
        {NAAC_DATA &&
          NAAC_DATA.map((items, index) => (
            <div className="d-flex mb-3" key={index}>
              <img src={Arrow_icon_right} alt="Arrow Icon" />
              <Typography
                variant="subtitle1"
                className="m-0 cursor-pointer"
                onClick={() => navigation_url(items)}
              >
                {items.title}
              </Typography>
            </div>
          ))}
      </div>
    </section>
  );
};

export default NAACComponent;
