import React, { useState, useEffect } from "react";
import HCarosel from "../Component/Carosel/Carosel";
import MissionVission from "./MV/MV";
import { CircularProgress, Fade, Box } from "@mui/material";
import HS from "./HS/HS";
import Events from "./Events/Events";
import ServicesCarosel from "../Component/Carosel/Services_Carosel";
import CourseOffering from "./Course_Offering/Course_Offering";
import StudyWithUs from "./Study_with_us/Study_with_us";
import Json_Data from "../Assets/JSON/MV.json";
import WelcomeModal from "../Component/WelcomeModal";
// import CardPeople from "../Component/Cards/CardPeople";
// import CardSocialMedia from "../Component/Cards/CardSocialMedia";

const Home = () => {
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [carouselVisible, setCarouselVisible] = useState(false);

  const MV_Data = {
    Mission: {
      tittle: "Mission",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/mission/mission.jpg",
      description:
        "In Pursuit of excellence in education. To install a zeal and passion to Educate, Organize and Struggle. To ensure bright future with confidence.",
    },
    Vission: {
      tittle: "Vision",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/vision/vision.jpg",
      description:
        "To empower the socially, economically, educationally backward sections of the society through education, training and self-employment.",
    },
  };

  const HSData = {
    tittle: "How we Started",
    image:
      "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/howwestarted/howwestarted.jpg",
    description:
      "Dr.B.R. Ambedkar Educational Institutions was started by Late. Sri G Venkataswamy Garu, former Union Minister, Govt. of India in the year 1973. These institutions are inaugurated by Sri V.V. Giri, by then Hon. President of India. Dr.B.R. Ambedkar Educational Institutions is nestled up throughout a 3 acre land in Baghlingampally, Hyderabad. Being in the heart of the city, it boasts of a huge campus, excellent infrastructure facilities and dedicated management and staff. The institutions group includes High School, Junior College, Degree College, Post-Graduate College, Law College and MBA College. Approximately, 3000 students in total are presently studying in the institutions. Apart from the academics, Dr. B.R. Ambedkar educational institutions excels in extra curricular activities too. We have one of the best National Cadet Corps (NCC) team,  3 NSS units. We have partnered with the esteemed Spotlight Cricket Academy which provides excellent cricket coaching for the age group 6 to 18 years.Dr.B.R. Ambedkar Degree College was started in the year 1973. It was granted Aid in the year 1977. Around 1100 students are studying in the degree college which offers courses including B.Com (general), B.com (computers),  BSc (MPC),  BSc (computers), B.Sc (electronics), BBA,  BA (Journalism) and  BA (EPP).",
  };

  useEffect(() => {
    const isModalShown = sessionStorage.getItem("welcomeModalShown");
    if (!isModalShown) {
      setWelcomeModalOpen(true);
      sessionStorage.setItem("welcomeModalShown", "true");
    }
  }, []);

  useEffect(() => {
    if (!welcomeModalOpen && isImageLoaded) {
      const timeoutId = setTimeout(() => {
        setCarouselVisible(true); // Delay to smoothly transition
      }, 500); // Delay to simulate smooth transition
      return () => clearTimeout(timeoutId); // Cleanup the timeout
    }
  }, [welcomeModalOpen, isImageLoaded]);

  const handleCloseWelcomeModal = () => {
    setWelcomeModalOpen(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <section className="home-container">
     {welcomeModalOpen && (
        <WelcomeModal open={welcomeModalOpen} onClose={handleCloseWelcomeModal} onImageLoad={handleImageLoad} />
      )}
     <>
     {!carouselVisible && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '20rem' }}>
          <CircularProgress />
        </Box>
      )}

      {/* Carousel is smoothly revealed with fade-in animation */}
      <Fade in={carouselVisible}>
        <div>
          <HCarosel />
        </div>
      </Fade>
          <StudyWithUs />
          <MissionVission MV_DATA={MV_Data} />
          <CourseOffering />
          <HS HS_DATA={HSData} />
          <ServicesCarosel />
          <Events
            Event_DATA={Json_Data?.HomePage_Details?.Events_Activities?.slice(0,4)}
            title="News and Events"
          />
        </>

      {/* <CardPeople getdata={PBData} />
      <CardSocialMedia getdata={PBData} /> */}
    </section>
  );
};
export default Home;
